import { FieldMetaProps } from 'formik';

export const parseAxiosError = (error: any) => {
  const axiosError =
    error?.response?.data?.error?.msg || error?.response?.data?.debug?.message;
  if (axiosError) {
    return axiosError;
  }
  return error.message;
};

export const getErrorMessage = (error: any) => {
  if (typeof error === 'string') return error;
  return (
    error?.response?.data?.error?.msg ||
    error?.response?.data?.debug?.message ||
    error?.message ||
    'Something went wrong'
  );
};

export const controlError = <T = any>(
  meta: FieldMetaProps<T>,
  name: string,
  label?: string
) => {
  let error = meta && meta.touched && meta.error;
  if (!error) return undefined;
  return error.replace(name, label || 'Value');
};
