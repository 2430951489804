import { css } from 'styled-components';

const ultraSmall = css``;

const small = css``;

const body = css``;

const heading1 = css``;

const heading2 = css``;

const heading3 = css``;

export const ultraSmallMedium = css`
  font-weight: 500;
  ${ultraSmall};
`;

export const smallLight = css`
  font-weight: 300;
  line-height: 18px;
  ${small};
`;

export const smallReqular = css`
  font-weight: 400;
  ${small};
`;

export const smallMedium = css`
  font-weight: 500;
  ${small};
`;

export const smallSemiBold = css`
  font-weight: 600;
  ${small};
`;

export const bodyRegular = css`
  font-weight: 400;
  ${body};
`;

export const bodySemibold = css`
  font-weight: 600;
  ${body};
`;

export const heading1Medium = css`
  font-weight: 500;
  ${heading1}
`;

export const heading2Medium = css`
  font-weight: 500;
  ${heading2}
`;

export const heading3Medium = css`
  font-weight: 500;
  ${heading3}
`;
