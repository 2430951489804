import { Anchor } from './anchors';

export const RTPI_UTM =
  '?utm_source=rtpiinfo&utm_medium=contactus&utm_campaign=rtpiinfo';

export const Routes = {
  notFound: '/notFound',
  home: '/',
  terms: '/terms',
  login: '/login',
  cookie: '/cookie',
  privacy: '/privacy',
  aboutUs: '/about-us',
  register: '/register',
  providers: '/slots/providers',
  responsibleGaming: '/responsible-gaming',
  contactUs: `/about-us#${Anchor.ContactUs}`,

  provider: (id: string) => `/slots/providers/${id}`,

  publicProfile: (id: string) => `/public-profile/${id}`,

  blogPosts: '/blog',
  guides: '/blog?type=guide',
  news: '/blog?type=news',
  rtpGuides: '/blog?type=rtpguide',
  blogPage: (id: string) => `/blog/${id}`,

  allCasinos: `/casinos/`,
  casinoPage: (id: string) => `/casinos/${id}`,
  casinoSlotReviewsPage: (id: string) => `/casinos/${id}/slot-reviews`,
  casinoReviewsPage: (id: string) => `/casinos/${id}/reviews`,
  casinoBigWinsPage: (id: string) => `/casinos/${id}/big-wins`,
  casinoCollections: (name: string) => `/casinos/collections/${name}`,

  allSlots: `/slots/`,
  slotPage: (id: string) => `/slots/${id}`,
  slotRtpReviewsPage: (id: string) => `/slots/${id}/rtp-reviews`,
  slotForRealMoneyPage: (id: string) => `/slots/${id}/for-real-money`,
  slotBigWinsPage: (id: string) => `/slots/${id}/big-wins`,
  slotCollections: (name: string) => `/slots/collections/${name}`,

  bigwins: '/big-wins',
  bigwin: (id: string) => `/big-wins/${id}`,
};
