import React, { useRef } from 'react';

import { Input as InputComponent } from 'common/input';
import { InputSize } from 'common/input/types';

import styled, { css } from 'styled-components';

interface RadioButtonProps {
  label: string;
  checked: boolean;
  onChange?: () => void;
}

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const Input = styled(InputComponent)`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  cursor: pointer;
`;

export const Radio = styled.span<{ checked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 370px;
  border-radius: 16px;
  border: ${({ theme }) => theme.config.radio.border.default};
  background: ${({ theme }) => theme.config.radio.background};
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 1900px) {
    width: 360px;
    height: 470px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    border: ${({ theme }) => theme.config.radio.checked.border.default};
    background: ${({ theme }) => theme.config.radio.background};
    border-radius: 50%;
    top: 12px;
    right: 12px;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;

    @media screen and (min-width: 1900px) {
      width: 20px;
      height: 20px;
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.config.radio.checked.border.default};
      &:before {
        transform: scale(1);
        opacity: 1;
        background: ${({ theme }) => theme.config.radio.checked.active};
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.config.radio.checked.border.default};
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Label = styled.div`
  margin-top: 32px;
  cursor: pointer;
`;

export const RadioButton = ({
  label,
  checked,
  onChange,
  ...rest
}: RadioButtonProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <Container>
      <Radio onClick={handleInputChange} checked={checked}>
        <Label>{label}</Label>
        <Input
          type="radio"
          ref={inputRef}
          checked={checked}
          size={InputSize.BIGGER}
          {...rest}
        />
      </Radio>
    </Container>
  );
};
