import clsx from 'clsx';
import styles from './styles.module.css';

const LoaderTemplate = {
  fitContent: 'fitContent',
  fullScreen: 'fullScreen',
};

const LoaderType = {
  primary: 'primary',
  secondary: 'secondary',
};

const LoaderSize = {
  small: 'small',
  medium: 'medium',
  big: 'big',
};

interface LoaderProps {
  type?: keyof typeof LoaderType;
  size?: keyof typeof LoaderSize;
  template?: keyof typeof LoaderTemplate;
}

export const RtpiLoader = ({ template, type, size }: LoaderProps) => {
  const classesContainer = clsx({
    [styles.container]: template === LoaderTemplate.fullScreen,
    [styles.mini]: template === LoaderTemplate.fitContent,
    [styles[size ?? '']]: Boolean(size),
    [styles.row]: type === LoaderType.primary,
  });

  const classesSpinner = clsx({
    [styles.spinnerElement]: true,
    [styles.spinnerElementRtpi]: type === LoaderType.primary,
  });

  return (
    <div className={classesContainer}>
      <div className={styles.spinner}>
        <div className={classesSpinner}></div>
        <div className={styles.spinnerInner}></div>
      </div>
      {type === 'primary' && <span>loading ...</span>}
    </div>
  );
};
