import { ButtonHTMLAttributes } from 'react';

import { ButtonVariant } from './types';
import IconWrapper from 'common/icon/icon-wrapper';

import * as Styles from './styles';

interface ButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  text: string;
  padding?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  variant?: keyof typeof ButtonVariant;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = ({
  to,
  text,
  icon,
  onClick,
  padding,
  disabled,
  variant = ButtonVariant.PRIMARY,
  ...props
}: ButtonBaseProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div>
      <Styles.StyledButton
        padding={padding}
        variant={variant}
        disabled={disabled}
        onClick={(e) => handleClick(e)}
        {...props}
      >
        {icon && (
          <Styles.IconButton withText={!!text?.length}>
            <IconWrapper>{icon}</IconWrapper>
          </Styles.IconButton>
        )}
        {text && <span>{text}</span>}
      </Styles.StyledButton>
    </div>
  );
};
