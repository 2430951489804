import { useEffect } from 'react';

export const useAutosizeTextarea = ({
  value,
  minHeight = 120,
  maxHeight = 400,
}: {
  value: string | number | readonly string[] | undefined;
  minHeight: number;
  maxHeight: number;
}) => {
  useEffect(() => {
    const textareaField = document.getElementById('autosize');

    const resizeTextarea = () => {
      if (textareaField) {
        textareaField.style.height = 'auto';
        const calculatedHeight = Math.max(
          textareaField.scrollHeight,
          minHeight
        );
        const currentHeight =
          calculatedHeight < maxHeight ? calculatedHeight : maxHeight;
        textareaField.style.height = `${currentHeight}px`;
      }
    };

    if (textareaField) {
      textareaField.addEventListener('input', resizeTextarea);
    }

    return () => {
      if (textareaField) {
        textareaField.removeEventListener('input', resizeTextarea);
      }
    };
  }, [value, minHeight]);
};
