import { HTMLProps } from 'react';

export enum InputColorType {
  DEFAULT = 'DEFAULT',
  LIGHT = 'LIGHT',
  GREEN = 'GREEN',
  GREY = 'GREY',
  DARK = 'DARK',
}

export enum InputForm {
  DEFAULT = 'DEFAULT',
  SQUARE = 'SQUARE',
}

export enum InputSize {
  ULTRA_SMALL = 'ULTRA_SMALL',
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
  BIGGER = 'BIGGER',
}

export enum IconSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum LabelSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export interface InputProps extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  type?: string;
  error?: string;
  label?: string;
  variant?: string;
  inputType?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  customStyle?: string;
  placeholder?: string;
  withBorder?: boolean;
  rightIcon?: JSX.Element;
  placeholderColor?: string;
  onChange?: (e: any) => void;
  style?: React.CSSProperties;
  onRightIconClick?: () => void;
  size?: keyof typeof InputSize;
  onBlue?: (value: boolean) => void;
  typeRightIcon?: 'inside' | 'outside';
  form?: keyof typeof InputForm;
  sizeIcon?: keyof typeof IconSize;
  sizeLabel?: keyof typeof LabelSize;
  colorType?: keyof typeof InputColorType;
  ref?: React.RefObject<HTMLInputElement>;
}
