import { useField } from 'formik';

import { FormikField } from 'types';
import { Input } from 'common/input';
import { InputProps } from 'common/input/types';
import { controlError } from 'utils/controlError';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { useMemo } from 'react';

export const InputMasks = {
  number: (value: string) => {
    return value.replace(/\D/g, '');
  },
  floatNumber: (value: string) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  },
  inn: (value: string) => {
    return value.replace(/\s/g, '').slice(0, 10);
  },
  phone: (value: string) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return value;
    }
    return value;
  },
  card: (value: string) => {
    return value
      .replace(/\s/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .slice(0, 19);
  },
  date: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})/, '$1/')
      .slice(0, 5);
  },
  cvv: (value: string) => {
    return value.replace(/\D/g, '').slice(0, 3);
  },
  username: (value: string) => {
    return value.replace(/[а-яА-Я!@~`#\$%\^\&*\)\(+=._-]/g, '');
  },
  email: (value: string) => {
    if (value && typeof value === 'string') {
      return value.replace(/[^a-zA-Z0-9.@-]+/g, '');
    }
    return value;
  },
};

interface InputFieldProps extends FormikField<InputProps> {
  mask?: (value: string) => string;
  typeRightIcon?: 'inside' | 'outside' | undefined;
}

export const InputField = ({
  name,
  mask,
  label,
  type,
  onChange,
  className,
  placeholder,
  typeRightIcon,
  ...props
}: InputFieldProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<string>(name);
  const error = t(
    controlError(meta, name, t(placeholder ?? '') as string) as string
  );

  const fieldValue = useMemo(() => {
    if (type === 'file') {
      return '';
    } else {
      return field.value ?? '';
    }
  }, [field.value, type]);

  return (
    <>
      <div className={styles.inputFieldContainer}>
        <Input
          {...props}
          error={error}
          type={type}
          value={fieldValue}
          customStyle={className}
          onBlur={() => helpers.setTouched(true)}
          label={label ? t(label ?? '') ?? '' : undefined}
          placeholder={placeholder ? t(placeholder ?? '') ?? '' : ''}
          onChange={(e: any) => {
            if (onChange) {
              onChange(e);
            } else {
              if (mask) {
                helpers.setValue(mask(e?.target?.value));
              } else {
                if (type === 'file') {
                  helpers.setValue(e?.target?.files[0]);
                } else {
                  helpers.setValue(e?.target?.value);
                }
              }
            }
          }}
        />
      </div>
    </>
  );
};
