import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { InputProps } from './types';

import { withRef } from 'hooks/withRef';
import { useAutosizeTextarea } from 'hooks/useAutosizeTextarea';

import styles from './styles.module.css';

export const Input = ({
  ref,
  type,
  icon,
  value,
  label,
  onBlur,
  style,
  onChange,
  variant,
  rightIcon,
  placeholder,
  customStyle,
  onRightIconClick,
  disabled = false,
  inputType = 'input',
  typeRightIcon = 'inside',
}: InputProps) => {
  const { t } = useTranslation();

  useAutosizeTextarea({ value, minHeight: 120, maxHeight: 300 });

  const textPlaceholder = placeholder ? t(placeholder) : '';

  const inputClassName = clsx(
    styles.input,
    variant && styles[variant],
    customStyle
  );

  const classesRightIcon = clsx({
    [styles.inside]: typeRightIcon === 'inside',
    [styles.outside]: typeRightIcon === 'outside',
  });

  return (
    <div className={styles.wrapper_input}>
      {label && <label className={styles.label}>{t(label)}</label>}

      <div>
        {inputType === 'textarea' ? (
          <textarea
            id="autosize"
            style={style}
            value={value}
            disabled={disabled}
            placeholder={textPlaceholder}
            onChange={(e) => onChange && onChange(e)}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            value={value}
            style={style}
            onBlur={onBlur}
            disabled={disabled}
            autoComplete="nope"
            className={inputClassName}
            placeholder={textPlaceholder}
            onChange={(e) => onChange && onChange(e)}
          />
        )}

        {icon && <span>{icon}</span>}

        {rightIcon && (
          <div
            className={classesRightIcon}
            onClick={() => {
              if (onRightIconClick) {
                onRightIconClick();
              }
            }}
          >
            {rightIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export const InputWithRef = withRef(Input);
