import styled, { css } from 'styled-components';

import { ButtonVariant } from './types';

export const StyledButton = styled.button<{
  padding?: string;
  variant?: keyof typeof ButtonVariant;
}>`
  width: 100%;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  box-sizing: border-box;
  transition: 250ms linear;

  height: 40px;
  border-radius: 24px;
  padding: ${({ padding }) => padding ?? '8px 26px'};

  ${({ variant, theme }) => {
    switch (variant) {
      case ButtonVariant.TRANSPARENT:
        return css`
          color: ${theme.colors.grey.secondary};
          &:hover {
            color: ${theme.colors.black.primary};
          }
          &:active {
            color: ${theme.colors.black.primary};
          }
        `;
      default:
        return css`
          color: ${theme.config.button.primary.text};
        `;
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case ButtonVariant.PRIMARY:
        return css`
          color: ${theme.config.button.primary.text};
          box-shadow: ${theme.config.button.primary.shadow.default};
          background: ${theme.config.button.primary.background.default};
          &:hover {
            background: ${theme.config.button.primary.background.hover};
          }
          &:active {
            background: ${theme.config.button.primary.background.active};
          }
        `;
      case ButtonVariant.SECONDARY:
        return css`
          color: ${theme.config.button.secondary.text};
          box-shadow: 0px 8px 16px
            ${theme.config.button.secondary.shadow.default};
          /* border: 1px solid ${theme.config.button.secondary.border
            .default}; */
          background-color: ${theme.config.button.secondary.background.default};
          &:hover {
            background: ${theme.config.button.secondary.background.hover};
          }
          &:active {
            background: ${theme.config.button.secondary.background.default};
          }
        `;
      case ButtonVariant.TETRIERY:
        return css`
          height: ${theme.responsive.isMobile ? 40 : 48}px;
          color: ${theme.config.button.secondary.text};
          box-shadow: ${theme.config.button.primary.shadow.secondary};
          background-color: ${theme.config.button.secondary.background.default};
          &:hover {
            background: ${theme.config.button.secondary.background.hover};
          }
          &:active {
            background: ${theme.config.button.secondary.background.default};
          }
        `;
      case ButtonVariant.CANCEL:
        return css`
          background-color: ${theme.colors.red.primary};
          &:hover {
            background: ${theme.colors.red.secondary};
          }
          &:active {
            background: ${theme.colors.red.tetriery};
          }
        `;
      case ButtonVariant.TRANSPARENT:
        return css`
          background: 'transparent';
          color: ${theme.colors.black.primary};
          &:hover {
            background: 'transparent';
            color: ${theme.colors.black.primary};
          }
          &:active {
            background: 'transparent';
            color: ${theme.colors.black.primary};
          }
        `;
      case ButtonVariant.DARK:
        return css`
          color: ${theme.colors.white.primary};
          background: ${theme.config.button.dark.background.default};
          &:hover {
            background: ${theme.config.button.dark.background.hover};
          }
          &:active {
            background: ${theme.config.button.dark.background.active};
          }
        `;
      case ButtonVariant.LIGHT:
        return css`
            color: ${theme.config.button.light.text};
            background: ${theme.config.button.light.background.default};
            &:hover {
              background: ${theme.config.button.light.background.hover};
            }
            &:active {
              background: ${theme.config.button.light.background.active};
        `;
      default:
        return css`
          box-shadow: ${theme.config.button.primary.shadow.default};
          background: ${theme.config.button.primary.background.default};
          &:hover {
            background: ${theme.config.button.primary.background.hover};
          }
          &:active {
            background: ${theme.config.button.primary.background.active};
          }
        `;
    }
  }};

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

export const IconButton = styled.span<{ withText: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: ${({ withText }) => (withText ? 12 : 0)}px;

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;
