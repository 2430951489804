import styled from 'styled-components';
import { SidenavVariant, SidenavVariantI } from 'constants/enums';

interface IconWrapperProps {
  fillColor?: string;
  fillColorHover?: string;
  fillColorActive?: string;
  strokeColor?: string;
  strokeColorHover?: string;
  strokeColorActive?: string;
  isCursor?: boolean;
  isInput?: boolean;
  width?: string;
  height?: string;
  isActive?: boolean;
  isSecondaryIcon?: boolean;
  sidenav?: keyof SidenavVariantI;
}

const IconWrapper = styled.div<IconWrapperProps>`
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  & > svg {
    & > * {
      fill: ${({
        theme,
        isActive,
        fillColor,
        fillColorHover,
        isSecondaryIcon,
      }) =>
        isActive
          ? fillColorHover || fillColor || theme.config.text.primary
          : isSecondaryIcon
            ? theme.config.icon.tetriary
            : fillColor || theme.config.icon.primary};
      stroke: ${({
        sidenav,
        isActive,
        strokeColorActive,
        strokeColor,
        theme,
      }) => {
        switch (sidenav) {
          case SidenavVariant.sidemenu:
            if (isActive) {
              return (
                strokeColorActive ||
                strokeColor ||
                theme.config.icon.green.active
              );
            } else {
              return strokeColor || theme.config.icon.green.default || 'none';
            }
          default:
            return strokeColor || 'none';
        }
      }};
    }
  }

  &:hover > svg > * {
    fill: ${({ sidenav, fillColorHover, fillColor, theme }) => {
      if (sidenav === SidenavVariant.sidemenu) {
        return fillColorHover || fillColor || theme.config.text.primary;
      } else {
        return fillColorHover || fillColor || theme.config.text.primary;
      }
    }};
    stroke: ${({ sidenav, strokeColorHover, strokeColor, theme }) => {
      if (sidenav === SidenavVariant.sidemenu) {
        return (
          strokeColorHover || strokeColor || theme.config.icon.green.active
        );
      } else {
        return strokeColorHover || strokeColor || 'none';
      }
    }};
  }

  &:active > svg > * {
    fill: ${({ fillColorActive, fillColor, theme }) =>
      fillColorActive || fillColor || theme.config.text.primary};
    stroke: ${({ isActive, strokeColorActive, strokeColor }) => {
      if (isActive) {
        return strokeColorActive || strokeColor || 'none';
      } else {
        return strokeColor || 'none';
      }
    }};
  }

  ${({ isCursor }) => isCursor && `cursor: pointer;`}
`;

export default IconWrapper;
