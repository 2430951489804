import { LIGHT_THEME } from 'config/themes';

export enum TextVariant {
  h1Medium = 'h1Medium',
  h2Medium = 'h2Medium',
  h3Medium = 'h3Medium',

  bodyRegular = 'bodyRegular',
  bodySemiBold = 'bodySemiBold',

  smallLight = 'smallLight',
  smallMedium = 'smallMedium',
  smallReqular = 'smallReqular',
  smallSemiBold = 'smallSemiBold',

  ultraSmallMedium = 'ultraSmallMedium',
}

export enum TextVariantColor {
  primary = 'primary',
  secondary = 'secondary',
  tetriery = 'tetriery',
  stableBlack = 'stableBlack',
}

export interface TextProps {
  styles?: any;
  children?: any;
  variant?: TextVariant;
  colorVariant?: keyof typeof LIGHT_THEME.config.text;
}
