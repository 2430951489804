export interface SidenavVariantI {
  sidemenu: string;
  default: string;
}

export const SidenavVariant: SidenavVariantI = {
  sidemenu: 'sidemenu',
  default: 'default',
};

export const UserRole = {
  Private: 'private person',
  Legal: 'legal person',
};

export const Status = {
  Review: 'review',
  Approved: 'approved',
  Rejected: 'rejected',
};

export const ButtonTable = {
  Menu: 'menu',
  Delete: 'delete',
};

export const zIndex = {
  Extra: 1000,
  Dropdown: 100,
  Modal: 1,
  None: 0,
};

export const MailingType = {
  MAILING: 'MAILING',
  VOICE_ROBOT: 'VOICE_ROBOT',
};

export const ParserType = {
  AVITO: 'AVITO',
  YANDEX: 'YANDEX',
  VK_POSTS: 'VK POSTS',
  VK_GROUPS: 'VK GROUPS',
};

export const SettingsCompanyType = {
  InfoCompany: 'info company',
  CreateCompany: 'create company',
};
